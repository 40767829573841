.DraftToolbar {
    border-bottom: $default-border;
    border-top: $default-border;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    min-height: 49px;

    @include for-phone-only {
        flex-direction: column;
    }

    .numbers {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 3px;

        .inner {
            align-items: center;
            display: flex;
        }

        p {
            font-size: 28px;
            margin: 0;
            margin-right: 20px;

            span {
                font-size: 22px;
                font-weight: lighter;
            }
        }

        .undo-button {
            padding: 10px 12px;
            border-radius: 25px;
            color: $secondary-font-color;
        }
    }

    .icon-container {
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
            font-size: 25px;
            margin-top: 5px;
            transition: transform ease-in .1s;
    
            &.down {
                transform: rotate(180deg);
            }
    
            @include for-phone-only {
                display: block;
            }
        }
    }

    .buttons {
        align-items: center;
        display: flex;
        overflow: hidden;
        transition: height ease-in .2s;

        @include for-phone-only {
            height: 45px;
            padding: 5px 0;

            &.closed {
                height: 0;
                padding: 0;
            }
        }

        button {
            border-radius: 25px;
            color: #fff;
            flex-grow: 1;
            font-size: 15px;
            margin-left: 10px;
            padding: 10px 15px;

            &.kicker {
                background-color: $kicker-light;
            }

            &.defense {
                background-color: $defense-light;
            }

            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}