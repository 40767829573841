@import './Variables.scss';

.Login {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 85vh;

    img {
        max-height: 200px;
    }

    form {
        display: flex;
        flex-direction: column;
        padding: 0 20px 10px 20px;
        margin: 0 auto;

        .form-input {
            margin-bottom: 15px;
            display: flex;
            flex-direction: column;
            
            label {
                font-size: .8rem;
                margin-bottom: 3px;
            }

            &:first-of-type {
                margin-top: 40px;
            }
        }

        input {    
            -webkit-appearance: none;
            padding: 8px;
            border: $darker-border;
            border-radius: 5px;
            min-width: 270px;
        }

        button {
            font-size: 16px;
            background-color: #27d086;
            color: white;
            margin-top: 5px;
            border-radius: 5px;
            align-self: flex-end;
            width: 100%;
            padding: 10px 0;
            box-shadow:
                0 0.5px 0.6px -8px rgba(0, 0, 0, 0.038),
                0 1.5px 1.8px -8px rgba(0, 0, 0, 0.055),
                0 3.6px 4.2px -8px rgba(0, 0, 0, 0.072),
                0 12px 14px -8px rgba(0, 0, 0, 0.11);

        }
    }

    .action-toggle {
        margin-top: 35px;

        .action-toggle-button {
            text-decoration: underline;
            color: $accent-color;
        }
    }

    .toggle {
        padding-left: 20px;
        margin-top: 40px;

        span {
            margin-right: 10px;
            font-size: 18px;
            cursor: pointer;

            &.active {
                font-weight: bold;
            }

            &:hover {
                font-weight: 500;
            }
        }
    }

    p {
        font-size: 14px;
        text-align: center;
        margin: 0;
        cursor: pointer;

        &:hover {
            color: #41ccb8;
        }
    }

    .error-message {
        color: red;
        font-size: 12px;
        height: 16px;
    }
}