@import "./Variables.scss";

.DraftsList {
    display: flex;
    justify-content: center;
    width: 100vw;

    >div {
        width: 100%;
        max-width: 550px;
        margin: 0 15px 15px;
    }

    h1 {
        font-weight: lighter;
        margin-bottom: 15px;
    }

    button {
        color: white;
        width: 100%;
        margin-bottom: 15px;
        border-radius: 30px;
        padding: 10px;
        font-size: 1.1em;
        background-color: $secondary-color;

        &:hover {
            background-color: $dark-button-hover;
        }
    }

    .list {
        border: $dark-border;
        border-radius: 5px;

        .draft-list-item {
            border-bottom: $dark-border;
            display: flex;
            justify-content: space-between;
            padding: 8px 20px;
            align-items: center;
            cursor: pointer;

            &:last-of-type {
                border-bottom: none;
            }

            .left {
                display: flex;
                flex: 1;
                flex-direction: column;

                .draft-position {
                    font-size: .8em;
                    color: $secondary-font-color;
                }
            }

            .right {
                display: flex;
                flex: 1;
                flex-direction: column;
                text-align: right;

                .time {
                    font-size: .8em;
                    color: $secondary-font-color;
                }
            }

            &:hover {
                background-color: $lighter-grey;
            }
        }

        .no-drafts {
            margin: 0 20px;
        }
    }

}