.DraftDrawer {
  display: flex;
  bottom: 0;
  z-index: 10;
  width: 100%;
  overflow: hidden;
  position: absolute;
  height: 60%;
  transition: all 0.2s ease;
  -webkit-box-shadow: 0px -5px 18px -7px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px -5px 18px -7px rgba(0,0,0,0.75);
  box-shadow: 0px -5px 18px -7px rgba(0,0,0,0.75);

  @include for-tablet-portrait-down {
    width: 100vw;
}

  &.full-size {
    height: 95%;
  }

  &.closed {
    height: 0%;
  }

  .tool-selector-mobile {
    display: flex;
    color: $dark-mode-tab;
    justify-content: space-evenly;
    padding: 12px 0 12px;
    font-weight: 600;
    font-size: .8rem;
    letter-spacing: 0.05rem;

    @include for-tablet-portrait-down {
      .tab.selected {
        text-decoration: underline;
        text-decoration-thickness: 3px;
        text-underline-offset: 6px;
      }
    }
  }

  .content-container {
    @include for-tablet-portrait-down {
      padding: 15px;
      height: calc(100% - 68px);
      overflow: auto;
    }
  }

  .drawer-size-controls {
    position: absolute;
    top: 0;
    right: 8px;
    color: $dark-mode-text-primary;
    display: flex;
    column-gap: 5px;

    .drawer-size-control {
      background-color: $dark-mode-tab-bg;
      opacity: .8;
      border-radius: 30px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: $dark-mode-tertiary;
      }
    }
  }

  .ranks {
    background: $dark-mode-secondary;
    border-radius: 7px 7px 0 0;
    flex: 2;
    height: 100%;
    // padding: 15px 15px 0;

    @include for-tablet-portrait-down {
      width: 100%;
    }

    .searchbar {
      position: relative;
      display: flex;
      align-items: center;

      input {
        color: $dark-mode-text-primary;
        padding: 10px 11px;
        font-size: 0.9rem;
        border-radius: 3px;
        border: none;
        min-width: 240px;
        background: #545677;

        &::placeholder {
           color: $dark-mode-text-hint;
        }
      }
    }
  }

  .tools {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    .tools-tabs {
      flex-basis: 42px;
      background: transparent;
      display: flex;
      
      .tab {
        flex-basis: 28%;
        background: $dark-mode-tab-bg;
        border-radius: 6px 6px 0 0;
        height: 100%;
        color: $dark-mode-tab;
        padding: 0 20px;
        display: flex;
        align-items: center;
        font-size: .8rem;
        font-weight: 700;
        letter-spacing: 0.05rem;
        box-shadow: 2px -3px 4px rgba(0, 0, 0, 0.25);
        cursor: pointer;

        &.selected {
          background: $dark-mode-tertiary;


        }


      }
    }
    
    .tools-content {
      background: $dark-mode-tertiary;
      overflow: auto;
      flex: 1;
      padding: 15px 25px;
    }
  }
}