.DraftArena {
  height: 100vh;
  position: relative;
  background: #171827;

  &.full-screen-ranks {
    > .DraftDrawer {
      height: 99%;
    }
  }

  &.full-screen-board {
    > .DraftDrawer {
      height: 6%;
    }

    > .board-container {

      > .DraftBoard {
        padding-bottom: 0;
      }
    }
  }

  .back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    background: transparent;
  }

  .board-container {
    height: 88%;
    overflow: auto;
  }

  .ranks-container {
    bottom: 0;
    z-index: 10;
    background: white;
    width: 100%;
    overflow: hidden;
    position: absolute;
    height: 65%;
    transition: all 0.2s ease;
  }
}