.DraftBoard {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    margin-top: 15px;
    -webkit-overflow-scrolling: auto;
    width: 100vw;
    column-gap: 6px;
    padding-bottom: 58vh;

    @media only screen and (min-width: 1380px) {
        justify-content: center;
        width: auto;
    }

    .team-column {
        min-width: 96px;
    }

    .team-column, .round-column {
        display: flex;
        flex-direction: column;
        row-gap: 6px;

        &:last-of-type {
            padding-right: 15px;
        }

        .header {
            max-width: 80%;
            margin: 0 auto;
            height: 24px;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 2px;
            justify-content: flex-end;

            h6 {
                margin: 0;
                text-align: center;
                color: #7d7d7d;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .claim-button {
                color: $dark-mode-bg-secondary;
                background-color: #05cdb8;
                border-radius: 15px;
                font-size: .7rem;
                text-align: center;
                padding: 2px 13px;
                cursor: pointer;
            }
        }

        .draft-pick-card {
            background-color: $dark-mode-bg-secondary;
            border-radius: 4px;
            height: 42px;
            color: white;
            padding: 5px;
            font-size: .8rem;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            position: relative;

            &.drafted {
                cursor: pointer;
            }

            .top {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .name {
                    line-height: 1;
                    width: 75px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow-x: hidden;
                    display: flex;
                    flex-direction: column;

                    .last-name {
                        font-weight: 600;
                    }
                }

                .round-pick {
                    font-weight: 500;
                    color: $dark-mode-alt-text;
                    font-size: .7rem;
                }
            }

            .bottom {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                font-size: .7rem;

                img {
                    height: 33px;
                    position: absolute;
                    right: 6px;
                    bottom: 1px;
                }
            }

            .current-pick {
                text-align: center;
                margin-top: 2px;
                font-weight: 500;
                font-size: .75rem;
                color: $dark-mode-text-primary;
            }

            &.on-the-clock {
                background-color: $dark-mode-otc-cell;
                justify-content: flex-start;

                .round-pick {
                    color: $dark-mode-text-primary;
                }
            }

            &.QB {
                background-color: $dark-mode-qb;
            }

            &.RB {
                background-color: $dark-mode-rb;
            }

            &.WR {
                background-color: $dark-mode-wr;
            }

            &.TE {
                background-color: $dark-mode-te;
            }

            &.DST {
                background-color: $dark-mode-defense;
            }

            &.K {
                background-color: $dark-mode-kicker;
            }

            &.QB,
            &.RB,
            &.WR,
            &.TE,
            &.DST,
            &.K {
                .round-pick {
                    color: #fff;
                }
            }
        }
    }
    
    @media only screen and (max-width: 760px) {
        .round-column {
            display: none;
        }
        
        :nth-child(1 of div.team-column) { 
        // .team-column:first-of-type {
            padding-left: 15px;
        }
    }

}