@import './Variables.scss';

.Rankings {
    height: calc(100% - 50px);

    .ranks {
        display: flex;
        flex-direction: column;
        max-height: 100%;//calc(100vh - 50px);

        @include for-tablet-portrait-down {
            max-height: calc(100vh - 102px);
        }

        .Group {
            width: 100%;
        }

        .groups {
            display: flex;
            margin: 3px 0;
            max-height: 100%;
            overflow: auto;
        }
    }

    .PlayerQueue {
        h3 {
            margin: 0;
            margin-top: 5px;
            font-weight: 300;
        }

        .empty-queue {
            display: flex;
            justify-content: center;
            margin-top: 150px;

            h4 {
                color: #ababab;
                font-weight: normal;
            }
        }
    }
}