.Group {
    border-left: $default-border;
    overflow: auto;
    padding: 0 10px;
    -webkit-overflow-scrolling: touch;

    &:first-of-type {
        border-left: none;
    }

    >div:last-of-type {
        padding-bottom: 5px;
    }

    .hide-tier {
        display: none;
    }

    .tier-header {
        background: white;
        padding-bottom: 5px;
        position: sticky;
        top: 0;
        z-index: 1;

        .tier-number {
            line-height: .5;
            background-color: white;
            bottom: -3px;
            color: $accent-color;
            font-size: .7em;
            font-weight: 600;
            margin: 0 auto;
            padding: 0 15px;
            position: relative;
            text-align: center;
            width: fit-content;
        }

        hr {
            margin: 0;
            border-bottom: none;
            border-top: 1px solid $accent-color;
        }

        &.all-drafted {
            .tier-number {
                color: $light-grey;
            }

            hr {
                border-top: 1px solid $light-grey;
            }
        }
    }

}

.player-row {
    display: flex;
    padding: 4px 10px;
    align-items: center;
    background-color: white;

    &:last-of-type {
        border: none;
    }

    &.flag-ALL_IN {
        background-color: $all-in-green-light;
    }
    
    &.flag-NEUTRAL {
        background-color: $neutral-yellow-light;
    }
    
    &.flag-ALL_OUT {
        background-color: $all-out-red-light;
    }

    img {
        height: 28px;
        margin-right: 4px;
    }

    .player-rank {
        color: $secondary-font-color;
        flex-basis: 7%;
        font-size: .9em;
        padding-right: 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;


        &.AllIn {
            border-right: 5px solid $all-in-green;
        }

        &.Neutral {
            border-right: 5px solid $neutral-yellow;
        }

        &.AllOut {
            border-right: 5px solid $all-out-red;
        }
    }

    .player-rank-num {
        margin-bottom: 5px;
        min-width: 24px;
        margin-right: 10px;
        text-align: right;
        font-size: .9rem;
        color: $secondary-font-color;
    }

    .player-name {
        flex: 1;
        font-size: .9em;
        line-height: 1.2;
        // padding-left: 6px;

        .name {
            // max-width: 110px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .player-team-info {
            color: $ternary-font-color;
            font-size: .7em;
        }
    }

    .sos {
        font-size: .9em;
        font-weight: lighter;
        line-height: 1.2;
        margin: auto 0;
        // padding-right: 20px;
        text-align: right;

        div {
            color: $secondary-font-color;
            font-size: .7em;
            font-weight: bold;
        }
    }

    .player-adp {
        flex-basis: 10%;
        font-size: .9em;
        font-weight: lighter;
        line-height: 1.2;
        margin: auto 0;
        // padding-right: 20px;
        text-align: right;

        div {
            color: $secondary-font-color;
            font-size: .7em;
            font-weight: bold;
        }
    }

    .draft-button {
        background-color: $secondary-color;
        color: white;
        font-size: 14px;
        height: fit-content;
        margin-left: 10px;
        padding: 7px 13px;
        border-radius: 25px;

        &.is-user-pick {
            background-color: $user-pick-green;
        }
    }

    .queue-button {
        color: white;
        background-color: #7b7b7b;
        font-size: 12px;
        border-radius: 25px;
        height: 30px;
        width: 30px;
        padding: 0;
        margin-left: 10px;

        &.queued {
            background-color: #31cbd2;
        }
    }

    &.drafted {
        opacity: .5;

        button {
            background-color: $medium-grey;
            opacity: 1;
        }

        &.hide-drafted {
            display: none;
        }
    }

    &.selected {
        background-color: $light-grey;
    }
}