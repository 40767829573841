.UserRanks {
    display: flex;
    flex-direction: column;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;

        button {
            border-radius: 5px;
            font-weight: 600;
            color: white;
            background-color: #00b0ff;
            padding: 8px 17px;

            &:disabled {
                background-color: #e0e0e0;
            }
        }
    }

    .ranks {
        display: flex;
        
        @include for-phone-only {
            flex-direction: column;
        }

        .tiers {
            flex: 3;
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 1fr 1fr 1fr;
            flex-wrap: wrap;
            align-content: flex-start;
            padding: 0 10px;
            gap: 10px;
            // display: flex;
            // flex-direction: column;
            // flex: 1;

            // .Group {
            //     border: none;
            //     max-height: calc(100vh - 58px);

            //     @include for-tablet-portrait-down {
            //         max-height: calc(100vh - 111px);
            //     }
            // }

            @include for-phone-only {
                grid-template-columns: 1fr;
                padding-bottom: 15px;
            }

            @include for-desktop-up {
                grid-template-columns: repeat(4, 1fr);
                max-width: 1440px;
                margin: 0 auto;
            }

            > div {
                flex: 1;
            }

            .tier-bucket {
                border-radius: 5px;
                border: $default-border;
                // min-width: 300px;
                // margin: 10px;
                box-shadow: 3px 3px 9px -6px rgba(95,95,95,0.75);
                // height: calc(100% - 20px);
                height: 100%;

                @include for-phone-only {
                    width: 100%;
                }

                header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px;
                    border-bottom: $default-border;

                    h3 {
                        margin: 0;
                        font-weight: 600;
                    }

                    svg {
                        cursor: pointer;
                    }
                }

                .player-list {
                    padding-bottom: 40px;

                    

                    &.empty-list {
                        display: flex;
                        justify-content: center;

                    }

                    p {
                        color: #ababab;
                        font-size: .9em;
                    }
                }

            }
        }

        .unranked-players {
            flex: 1;
            margin: 10px;
            border-radius: 5px;
            border: $default-border;
            box-shadow: 3px 3px 9px -6px rgba(95,95,95,0.75);
            max-width: 400px;


            header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                border-bottom: $default-border;

                h3 {
                    margin: 0;
                    font-weight: 600;
                }

                svg {
                    cursor: pointer;
                }
            }

            h3 {
                font-size: 1.1rem;
                font-weight: 500;
                @include for-phone-only {
                    margin-left: 10px;
                    
                }
            }
        }
    
    }
    .player-card {
        flex: 1.5;
        margin: 10px;
        padding: 0 15px;
        border: $default-border;
        border-radius: 5px;
    }

}