.Player {
    margin: 15px;
    display: flex;
    flex-direction: column;

    .icon {
        position: absolute;
        right: 15px;
        top: 15px;
    }

    header {
        display: flex;
        align-items: flex-end;
        margin: 0 5px 15px;

        h3, h4 {
            margin: 0;
        }

        h4 {
            color: $secondary-font-color;
            font-weight: normal;
        }

        img {
            height: 70px;
            margin-right: 15px;
        }
    }

    h3 {
        margin-bottom: 10px;
        font-weight: 500;
    }

    p {
        max-width: 100%;
    }

    .info-cells {
        border: $default-border;
        border-radius: 5px;
        display: flex;

        .cell {
            padding: 7px;
            border-right: $default-border;
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: space-between;
            align-items: center;

            .cell-header {
                font-size: .8em;
                font-weight: bolder;
                color: $ternary-font-color;
            }

            .cell-data {
                font-size: 1.2em;
                padding-top: 4px;

                &.low-risk {
                    color: $low-risk-green;
                }

                &.medium-risk {
                    color: $medium-risk-yellow;
                }

                &.high-risk {
                    color: $high-risk-red;
                }
            }

            &:last-of-type {
                border-right: none;
            }
        }
    }

    textarea {
        border: $default-border;
        font-family: inherit;
        min-height: 280px;
        flex: 1;
        font-size: 1rem;
        padding: 10px;
        border-radius: 3px;
        margin-bottom: 10px;
    }

    button {
        background-color: $secondary-color;
        color: $lighter-grey;
        padding: 10px;
        font-size: 1.2rem;
        border-radius: 70px;
    }
}