$secondary-font-color: #878787;
$ternary-font-color: #c0c0c0;
$darker-grey: #d2d2d2;
$dark-grey: #cccccc;
$medium-grey: #e6e6e6;
$light-grey: #f1f1f1;
$lighter-grey: #fbfbfb;
$secondary-color: #5b739a; //#6c7b95;
$accent-color: #00adb5;
$default-border: 1px solid $light-grey;
$dark-border: 1px solid $medium-grey;
$darker-border: 1px solid $darker-grey;
$user-pick-green: #85e615;

$dark-button-hover: #4c6081;

$sidebar-width: 70px;
$bottom-bar-height: 50px;

//User Ranks
$all-in-green: #9ccf31;
$all-in-green-light: rgba(156, 207, 49, .2);
$neutral-yellow: #f7d708;
$neutral-yellow-light: rgba(247, 215, 8, .2);
$all-out-red: #fa6363;
$all-out-red-light: rgba(250, 99, 99, .2);

$low-risk-green: #238823;
$medium-risk-yellow: #ffbf00;
$high-risk-red: #d2222d;

//Player Position Colors
$qb: #ff2b6d;
$rb: #00ceb8;
$wr: #58a7ff;
$te: #ffae58;
$kicker: #bd66ff;
$kicker-light: rgba(189, 102, 255, .8);
$defense: #022047;
$defense-light: rgba(2, 32, 71, .8);

/**
  Dark mode
*/
$dark-mode-text-primary: rgba(255,255,255,0.87);
$dark-mode-text-secondary: rgba(255,255,255,0.7);
$dark-mode-text-hint: rgba(255,255,255,0.6);
$dark-mode-bg-primary: #171827;
$dark-mode-bg-secondary: #1F2137;
$dark-mode-secondary: #28293B;
$dark-mode-tertiary: #2F3142;
$dark-mode-tab-bg: #262836;
$dark-mode-alt-text: #c0c0c0;
$dark-mode-tab: #09DDDD;
$dark-mode-qb: #E91E63;
$dark-mode-rb: #28AE56;
$dark-mode-wr: #4F3CC9;
$dark-mode-te: #EE8E48;
$dark-mode-kicker: #bd66ff;
$dark-mode-kicker-light: rgba(189, 102, 255, .8);
$dark-mode-defense: #8e3930;
$dark-mode-defense-light: rgba(2, 32, 71, .8);
$dark-mode-otc-cell: #7579AB;